<template>
  <div class="goodNews">
    <Head classA="3" headBg="1"></Head>
    <div class="main">
      <h2>用户心声|致医云诊室，与之相遇，便不相离</h2>
      <p class="time">2019-12-19</p>
      <p>
        <span class="indent0">古 有美一人，清扬婉兮，邂逅相遇，适我愿兮，</span>
        <span class="indent0"
          >今 有app一枚，灵巧妙兮，邂逅相遇，相见恨晚兮。</span
        >
        <span class="indent0">致医云诊室app，与之相遇，便不会离弃。</span>
        <span class="indent0"
          >让我们一起看看河北沧州的基层医生们与云诊室邂逅碰撞出的美丽火花吧。</span
        >
      </p>
      <h4>沧州运河西环颐和文园社区卫生服务站</h4>
      <img src="../../assets/images/newsDetail/userVoice12/n1.png" alt />
      <p>
        <span
          >18年5月份接触云诊室后，用的最多的就是中药西药开方(看病开方)功能，原来手写的处方存储不行，查方子也不方便，有了这个电脑(一体机云诊室系统)省了事，一个是(处方)模板做好以后，就省着手写了，特别简单。原先接待病人还得先登记，老患者还得重新登记，现在有这个接待病人也比原先快多了，一般有个两三分钟就能看好，所以比原先一个效率比较快，一个是质量也比较好，病人所以比原先要多一点。再有，最早以前的时候就是排号，有时候来号不及时，或者是号比较乱，病人经常吵架，通过按上云屏叫号以后，也方便了病人，这个秩序也比较正常了。好多病人来了以后看到这个新东西，也比较新奇的感觉，所以会高看一点。</span
        >
      </p>
      <h4>皇家壹里社区卫生服务站</h4>
      <img src="../../assets/images/newsDetail/userVoice12/n2.png" alt />
      <p>
        <span
          >用了系统以后，有利于保存，病人的每一次诊察记录都能搜索到，不用再去翻档案翻处方了，敲几个字母就能找到原来的病历档案，更便捷。处方模板，省去了大夫手写的繁琐工作，不光是快，又方便，诊疗所有大夫又具有了统一性。门诊电子化，这是个趋势，我们做的更超前一点。</span
        >
      </p>
      <h4>李瞳村卫生室</h4>
      <img src="../../assets/images/newsDetail/userVoice12/n3.png" alt />
      <p>
        <span
          >有很多优点，比如说，咱们开完处方以后，病人回家服药，有微信提醒，到点该吃药了提醒吃药，该复查了提醒复查，这一方面很好，对我们大夫来说比较省心，不用挨个打电话随访了，病人收到信息，也感觉很好，服务比以前更周到了。库存管理，快到期的还有过期的药都会提前告诉，避免损失，不用老上库房了。咱的药也多，药价记不过来，不知道药价的情况下，只要药品入了库，一扫码就出来，很方便。</span
        >
      </p>
      <h4>赵春振诊所</h4>
      <img src="../../assets/images/newsDetail/userVoice12/n4.png" alt />
      <p>
        <span
          >患者的资料、病程记录、用药经历都可以存档，如果患者复诊，之前的情况用药啊会一目了然，调之前的病历特别方便。以前是纸质处方，找病历要翻好半天，现在一输名字，一翻就都有了。还有就是过敏史啊，慢性病史啊，这个也可以一目了然。还有比较实用，对广大的病号也方便了，直接可以在家挂号，方便了病人。</span
        >
      </p>
      <h4>王拥诊所</h4>
      <img src="../../assets/images/newsDetail/userVoice12/n5.png" alt />
      <p>
        <span
          >使用系统以后，是从纸质办公到电子化办公的一个进步，跟病人交流起来要方便些。可以给病人发医嘱，用药提醒，让医患关系往好的方向发展，使诊疗相对来说要简单。进销存管理，进药销药更准确，管理的更细致。使用系统让患者感觉更正规\高大上，医生也感觉高大上。</span
        >
      </p>
    </div>
    <Foot></Foot>
  </div>
</template>
<style scoped>
@import '../../assets/style/goodNews.css';
</style>
<script>
import Foot from '@/components/common/Foot'
import Head from '@/components/common/Head'

export default {
  name: 'news',
  components: {
    Head,
    Foot
  },
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>
